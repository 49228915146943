<template>
	<div class="cbox">
		<div v-if="false" class="headbar">

			<div style="padding-top: 14px;position:relative">

				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">就业名册</div>
				</div>

				<!-- <div style="position: absolute; right: 0; top:0">
					<el-button type="infor" @click="donwAll" size="small" icon="el-icon-download">下载全部文件</el-button>
				</div> -->

			</div>
		</div>
		<div class="w_100 h_100 bcontent flexStart borderbox" style="padding:20px 0;">

			<!-- <div style="height:82vh; width:250px; padding-left:10px; overflow:auto; float: left;">
					<div :class="['school', schoolId == 'all' ? 'ckd' : '']" @click="setSchool('all')">全部学校</div>
					<div :class="['school', schoolId == item.id ? 'ckd' : '']" @click="setSchool(item.id)"
						v-for="(item, idx) in SchoolList" :key="idx">{{ item.platform }}<i v-if="!item.hasData"
							class="el-icon-star-on" style="color:orangered"></i></div>
				</div> -->

			<div class="w_100 h_100 flexCloumn" id="myFullTable" @dblclick="initFullTables"
				style="background-color: #fff;">
				<div class="w_100 flexBetween">
					<el-tabs class="w_100" v-model="activeName" @tab-click="handleClick">

            <el-tab-pane label="就业数据统计表(合计)" name="合计" v-if="xuezhiList.length>1"></el-tab-pane>
						<el-tab-pane :label="'就业数据统计表('+item.xuezhi+')'" :name="item.xuezhi"
							v-for="(item,i) in xuezhiList" :key="i">

						</el-tab-pane>




					</el-tabs>
					<el-tabs v-if="xuezhiList && xuezhiList.length>1" class="" v-model="activeName">
						<el-tab-pane disabled>
							<div slot="label" class="flexStart">

								<el-button v-if="$store.state.pmid == 1" icon="el-icon-download" size="small"
									type="text" title="全部学制" @click="downXls(1)">下载全部</el-button>

								<el-button class="ml20" @click="initFullTables" size="middle" type="text"
									title="全屏展示表格，也可双击表格全屏展示" style="margin-left:20px;">
									<i class="el-icon-full-screen" style="font-size:24px!important;"></i></el-button>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="w_100 flexCenter"
					style="text-align: center;font-size: 30px !important; padding-bottom: 20px;">
					南京市职业学校{{year}}届毕业生就业数据统计表（{{activeName}}）

					<el-button class="ml10" @click="downXls(0)" type="primary" plain circle size="mini"
						icon="el-icon-download"></el-button>
				</div>
				<el-table id="table" ref="table" :data="tableData" row-key="id" border stripe show-summary
					:summary-method="getSummaries" class="flex_1" height="100%" style="width:fit-content;width:100%;">
					<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
					<!-- <el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column> -->
					<el-table-column label="专业大类" prop="type_name" min-width="120" align="center"></el-table-column>
					<el-table-column label="毕业生数" prop="bys_num" width="120" align="center"></el-table-column>
					<el-table-column label="就业人数" prop="jiuye_num" width="120" align="center"></el-table-column>
					<el-table-column label="直接就业人数" prop="zhijie_num" width="120" align="center"></el-table-column>
					<el-table-column label="升学人数" prop="sx_num" width="120" align="center"></el-table-column>
					<el-table-column label="参军人数" prop="canjun_num" width="120" align="center"></el-table-column>
					<el-table-column label="对口就业人数" prop="dk_num" width="120" align="center"></el-table-column>
					<el-table-column label="待业人数" prop="daiye_num" width="120" align="center"></el-table-column>
					<el-table-column label="就业率" prop="jyl" width="120" align="center"></el-table-column>
					<el-table-column label="对口就业率" prop="dkjyl" width="120" align="center"></el-table-column>


				</el-table>

			</div>


		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			schoolId: 0,
			tjData: null,
			SchoolList: [],
			activeName: "",
			xuezhiList: [],
			tableData: [],
			year:sessionStorage.getItem("year"),
			xuezhiListExport: [],

		}
	},
	mounted() {
		this.getXuezhi()
		//this.getDataList()

	},
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
  watch: {
    tableData: {
      immediate: true,
      handler() {
        setTimeout(() => {
          const tds = document.querySelectorAll('#table .el-table__footer tr>td');
          tds[0].colSpan = 2;
          tds[0].style.textAlign = 'center'
          tds[1].style.display = 'none'
        }, 0)
      },
    }
  },
	methods: {
		
		getXuezhi() {
			this.$http.post("/api/get_xuezhi_list").then(res => {
				this.xuezhiList = res.data
				if (res.data && res.data.length>1) {
					this.xuezhiListExport = res.data.concat({ xuezhi: "合计" })
          this.activeName = '合计'
				} else {
					this.xuezhiListExport = res.data
          this.activeName = res.data[0].xuezhi
				}
				this.getData()
			})
		},
		
		getData() {
			let data = {
				xuezhi:this.activeName == '合计' ? "" : this.activeName
			}
			this.$http.post("/api/get_zydltj_table", data).then(res => {
        res.data.map(d => {
          d.jyl = d.bys_num ? parseInt(d.jiuye_num / d.bys_num * 10000) / 100 + "%" : ''
          d.dkjyl = d.bys_num ? parseInt(d.dk_num / (d.bys_num - d.daiye_num) * 10000) / 100 + "%" : ''
        })
				this.tableData = res.data
		
		
			})
		},
		handleClick(){
			this.getData()
		},
		
		
		getSchoolList() {
			this.$http.post("/api/school_list").then(res => {
				let SchoolList = res.data
				this.$http.post("/api/get_jiuye_tjschools").then(res2 => {
					for (let item of res2.data) {
						for (let s of SchoolList) {
							if (s.id == item.pmid) {
								s.hasData = true
							}
						}
					}
					this.SchoolList = SchoolList
					for (let item of SchoolList) {
						if (item.hasData) {
							this.setSchool(item.id)
							break
						}
					}
				})
			})
		},
		setSchool(id) {
			this.schoolId = 0;
			this.tjData = null;
			setTimeout(() => {
				this.schoolId = id
				this.getXuezhi()
			}, 200)

		},
		/*handleClick() {
			if (this.activeName == "second") {
				this.getDataList()

			}
		},*/
		getDataList() {
			return
			this.$http.post("/api/get_jiuye_tjtable", { tpmid: this.schoolId }).then(res => {
				if (!res.data) {
					this.$message.error("还没有上传统计表")
				} else {
					this.tjData = res.data
					this.$nextTick(e => {
						$("table", "#tableContent").addClass("dttable")
					})
				}



			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.xunfang = e

			this.dialogShow = true
		},
		uploadFiles(e) {
			if (e.src.indexOf("doc") > 0) {

				this.$http.post("/api/import_jiuyetj_word", {
					url: e.src
				}).then(res => {


					this.$message.success("导入成功")
					if (res.data.msg && res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogshow1 = false
					this.activeName = "second"
					this.getDataList()
					setTimeout(() => {
						this.handleClick()
					}, 500);



				})

			} else {
				this.$http.post("/api/import_jiuyefl_xls", {
					url: e.src
				}).then(res => {

					this.getDataList()
					this.$message.success("导入成功")
					if (res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg['巡访学生'] + ":" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogShow = false
					this.activeName = "none"
					this.$nextTick(e => {
						this.activeName = "third"
					})


				})
			}
		},
		donwAll() {
			this.$http.post("/api/all_files_down", {
				school_id: this.schoolId
			}).then(res => {
				if (res.data.file) {
					
					window.open(res.data.file, "_blank")
				} else {
					this.$message({
						type: 'error',
						message: '该学校未上传统计表!'
					});
				}
			})

		},
		downXls(isall) {
			this.$http.post("/api/export_zydltjb", {
				xuezhi: this.activeName == '合计' ? '' : this.activeName,
				year: this.year,
        isall: isall
			}).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
				}

			})
		},
		downloadAll() {
			this.$http.post("/api/export_zydltjb_all", {
				xuezhiList: this.xuezhiListExport,
				year: this.year
			}).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
				}

			})
		},
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      const sumMap = {
        jiuye_num: 0,
        bys_num: 0,
        daiye_num: 0,
        dk_num: 0
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sumMap[column.property] = values.reduce((prev, curr) => (isNaN(curr) ? prev : prev + curr), 0)
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
        if (column.property === 'jyl') {
          //sums[index] = 100;
          sums[index] = this.calculatePercentage(sumMap.bys_num, sumMap.jiuye_num, 'tableJyl')
        }
        if (column.property === 'dkjyl') {
          //sums[index] = 100;
          sums[index] = this.calculatePercentage((sumMap.bys_num - sumMap.daiye_num), sumMap.dk_num, 'tableDkjyl')
        }
      });
      return sums;
    },
    // 计算百分比
    calculatePercentage(total, part, sumProperty) {
      if (total && part) {
        return this[sumProperty] = parseInt(part / total * 10000) / 100 + "%"
      } else {
        return '0'
      }
    },

	}
}
</script>

<style scoped>
.school {
	line-height: 30px;
	border-radius: 4px;
	cursor: pointer;
	padding: 5px 10px;
}

.school:hover {
	background-color: aliceblue;

}

.ckd {
	background-color: #547df2;
	color: #fff;
}

.ckd:hover {
	background-color: #547df2;
	color: #fff;
}
</style>